import {React, useEffect} from '../../../../imports/imports-packege';
import PWAPreviewStyle from '../PWAPreviewStyle.module.css';
import NoBanner from '../no_banner.jpg';
import {useData} from "../../../Context/ContextApp";

const SectionScrollBanner = () => {
    let {data} = useData();
    useEffect(() => {
    }, [data])

    return (
        <section className={PWAPreviewStyle.scroll_1}>
            <div className={PWAPreviewStyle.scroll__container_1}>
                {
                    data.screenshotImages.length === 0 ?
                        <div className={`${PWAPreviewStyle.item_1} js-event`} >
                            <img src={NoBanner} alt="" style={{width: 'max-content', height: 160, objectFit: 'contain'}}/>
                        </div> : <>
                            {Object.keys(data.screenshotImages).map((key, index) => (
                                <div key={index} className={`${PWAPreviewStyle.item_1} js-event`}>
                                    <img src={data.screenshotImages[key]} alt="" style={{ width: 'max-content', height: 160, objectFit: 'contain' }} />
                                </div>
                            ))}
                        </>
                }
            </div>
        </section>
    );
};

export default SectionScrollBanner;